import React, { useEffect, useRef, useState } from 'react';
import "./chatbot.css";

import axios from "axios";

const Chatbot = () => {
	const [message, setMessage] = useState('');
	const [chatMessages, setChatMessages] = useState([]);
	const chatroomRef = useRef(null);
	const initialMessageSent = useRef(false);

	const sendMessage = async (message) => {
		const userMessage = { sender: "You", message };

		try {
			setChatMessages(prevMessages => [...prevMessages, { sender: "You", text: message }]);
			setMessage('');

			const response = await axios.post('https://lifesaver.nightingale.uni-mainz.de/api/ask_chatbot', userMessage, {
				headers: {
				  'Content-Type': 'application/json',
				},
			});

			for (const botMessage of response.data) {
				botMessage.text.split("\n\n").forEach(message => {
					if (!message.trim()) return;
					setChatMessages(prevMessages => [...prevMessages, { sender: "Chatbot", text: message }]);
				});

				if (botMessage.buttons) {
					setChatMessages((prevMessages) => [
					  ...prevMessages,
					  { sender: "Chatbot", buttons: botMessage.buttons },
					]);
				  }
			}
		} catch (error) {
			console.error('Error sending message:', error);
		}
	};

	const prepareAndSendMessage = async () => {
		if (!message.trim()) return;
		sendMessage(message);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			prepareAndSendMessage();
		}
	};

	const handleButtonClick = (payload) => {
		sendMessage(payload);
	};

	useEffect(() => {
		if (chatroomRef.current) {
		  chatroomRef.current.scrollTop = chatroomRef.current.scrollHeight;
		}
	}, [chatMessages]);

	useEffect(() => {
		if (!initialMessageSent.current) {
		  sendMessage("Hello!");
		  initialMessageSent.current = true;
		}
	  }, []);
	
	return (
	<div className='box'>
		<div className='chatroom-wrapper'>
			<div className='chatroom' ref={chatroomRef}>
				{chatMessages.map((msg, index) => (
					<React.Fragment key={index}>
						{msg.text && (
						<div className={msg.sender === 'You' ? 'user-message' : 'bot-message'}>
							{msg.text}
						</div>
						)}

						{msg.buttons && (
						<div className='buttons-container'>
							{msg.buttons.map((button, idx) => (
							<button
								key={idx}
								className='chat-button'
								onClick={() => handleButtonClick(button.payload)}
							>
								{button.title}
							</button>
							))}
						</div>
						)}
					</React.Fragment>
          		))}
			</div>
		</div>
		<div className='input-area'>
		<input
          type="text"
          className='message-input'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type your message..."
        />
        <button className='send-message-button' onClick={prepareAndSendMessage}>
          <svg xmlns="http://www.w3.org/2000/svg" className='icon-send-button' width="3em" height="3em" viewBox="0 0 24 24">
            <path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m3 3l3 9l-3 9l19-9Zm3 9h16"></path>
          </svg>
        </button>
		</div>
	</div>
	)
}

export default Chatbot